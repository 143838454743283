import { Link } from "@remix-run/react"
import { useMemo } from "react"
import { useIsMobile } from "~/hooks/use-mobile"
import { Image, Companion } from "~/types"
import Img from "./Img"
import { cn } from "~/lib/utils"

interface CompanionCoverCardPropts {
  companion: Companion
  className?: string
}

export function CompanionCoverCard({
  companion,
  className,
}: CompanionCoverCardPropts) {
  const isMobile = useIsMobile()

  const randomImages = useMemo(
    () => pickTwoImages(companion.profile_covers!),
    [companion]
  )
  const coverImage = randomImages[0].url!
  const hoverImage = isMobile ? coverImage : randomImages[1].url!
  const alt = `${companion.first_name} ${companion.last_name}`

  return (
    <div
      className={cn("overflow-hidden rounded-lg relative shrink-0", className)}
    >
      <Link to={`/companions/${companion.uuid}/chat`} className="group">
        <Img
          hideOnLoad
          alt={alt}
          src={coverImage}
          className="transition-transform duration-200 ease-in-out"
        />
        <Img
          hideOnLoad
          alt={alt + " Hover"}
          src={hoverImage}
          className="opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100 absolute top-0 left-0"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50"></div>
        <div className="absolute bottom-0 right-0 w-full px-2 py-4">
          <div className="space-y-2">
            <div>
              <h4 className="font-bold text-2xl sm:text-xl text-white">
                {companion.first_name} {companion.last_name}, {companion.age}
              </h4>
              <div className="text-white/90">{companion.occupation}</div>
            </div>
            <div className="text-sm text-white/90 line-clamp-2">
              {companion.backstory}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

function pickTwoImages(arr: Image[]) {
  if (arr.length === 0) {
    throw new Error("cannot select profile cover image from an empty array")
  }
  if (arr.length < 2) {
    return [arr[0], arr[0]]
  }
  const shuffled = arr.slice()
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }
  return shuffled.slice(0, 2)
}
